import React, { CSSProperties, useMemo } from "react";
import {
  colors,
  DateRange,
  DateRangeDropdown,
  DateRangePreset,
} from "@commonsku/styles";
import { format, isValid } from "date-fns";
import { FilterMainComponentProps } from "../Filter";

export const dateRangePickerFields = [
  "date_category",
  "start_stamp",
  "end_stamp",
];
export type DateRangePickerValues = [string, string, string];

const dateFormat = "yyyy-MM-dd";

export const DATE_RANGE_PRESETS: DateRangePreset[] = [
  {
    name: "today",
    label: "Today",
  },
  {
    name: "yesterday",
    label: "Yesterday",
  },
  {
    name: "this_week",
    label: "This Week",
  },
  {
    name: "last_week",
    label: "Last Week",
  },
  {
    name: "this_month",
    label: "This Month",
  },
  {
    name: "last_month",
    label: "Last Month",
  },
  {
    name: "this_quarter",
    label: "This Quarter",
  },
  {
    name: "previous_quarter",
    label: "Previous Quarter",
  },
  {
    name: "this_year",
    label: "This Year",
  },
  {
    name: "last_year",
    label: "Last Year",
  },
  {
    name: "last_ytd",
    label: "Last Year To Date",
  },
];

const dateRangeDropdownStyles: CSSProperties = {
  position: "absolute",
  marginTop: "1rem",
  padding: "1rem",
  background: colors.white,
  border: "2px solid",
  borderRadius: "5px",
  borderColor: colors.primary1["60"],
  zIndex: 2,
};

export interface DateRangePickerProps extends FilterMainComponentProps {
  values: DateRangePickerValues;
  onChange: (values: DateRangePickerValues) => void;
  placeholder: string;
}

const DateRangePicker = ({
  values,
  onChange,
  placeholder = "ALL",
}: DateRangePickerProps) => {
  const dateRange = useMemo(() => {
    let [category, start, end] = values;
    // Add time to start and end date, otherwise the date will be off by a day
    start += "T00:00:00";
    end += "T23:59:59";
    const startDate = isValid(new Date(start)) ? new Date(start) : null;
    const endDate = isValid(new Date(end)) ? new Date(end) : null;

    return {
      category,
      startDate,
      endDate,
    };
  }, [values]);

  const getDateString = (date?: Date | null) =>
    date != null ? format(date, dateFormat) : "";

  return (
    <DateRangeDropdown
      range={dateRange}
      placeholder={placeholder}
      presets={DATE_RANGE_PRESETS}
      onChange={(range: DateRange) =>
        onChange([
          range.category,
          getDateString(range.startDate),
          getDateString(range.endDate),
        ])
      }
      isClearable
      style={dateRangeDropdownStyles}
    />
  );
};

export default DateRangePicker;
