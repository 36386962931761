import React, { useCallback, useMemo } from "react";
import { Button } from "@commonsku/styles";
import { toast } from "react-toastify";
import { BulkActionConfigBase } from "../index";
import { dataToUrlParams, downloadCsv, oauth } from "../../../../utils";
import { useHasCapabilities } from "../../../../hooks";
import { useReportContext } from "../../report_context";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more commissions";
  }
  let label = '';
  const paid = selected.filter((row: any) => row.commission_id?.commission_paid === 1);
  const unpaid = selected.filter((row: any) => row.commission_id?.commission_paid === 0);
  if (paid.length > 0) {
    label += `${paid.length} paid commission${paid.length === 1 ? "" : "s"} selected`;
  }
  if (unpaid.length > 0) {
    if (paid.length > 0) {
      label += ".  ";
    }
    label += `${unpaid.length} unpaid commission${unpaid.length === 1 ? "" : "s"} selected`;
  }
  return label;
};

export function PayButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();

  const hasPay = useHasCapabilities(["MODIFY-COMMISSION"]);
  const commissionIds = useMemo(
    () => selected.filter(
      (row: any) => row.commission_id?.commission_paid === 0
    ).map(
      (row: any) => row.commission_id?.commission_id
    ),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    const data = {
      id: commissionIds.join(","),
      commission_paid: 1,
      date_commission_paid: (new Date()).toISOString().slice(0,10)
    };
    await oauth("PUT", "commission?" + dataToUrlParams(data));
    await downloadCsv(
      "report/commission?" + dataToUrlParams({ commission_ids: commissionIds }),
      "commissions.csv"
    );
    if (commissionIds.length === 1) {
      toast.success('1 commission marked paid');
    } else {
      toast.success(`${commissionIds.length} commissions marked paid`);
    }
    await fetchReport();
    setExecuting(false);
  }, [commissionIds, setExecuting, fetchReport]);

  if (!hasPay) {
    return null;
  }

  return (
    <Button
      disabled={disabled || commissionIds.length === 0}
      onClick={handleClick}
    >
      Pay
    </Button>      
  );
}

export function UnPayButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();

  const hasUnpay = useHasCapabilities(["MODIFY-COMMISSION"]);
  const commissionIds = useMemo(
    () => selected.filter(
      (row: any) => row.commission_id?.commission_paid === 1
    ).map(
      (row: any) => row.commission_id?.commission_id
    ),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    await oauth("POST", "commission", { action: "unpay", id: commissionIds });
    if (commissionIds.length === 1) {
      toast.success('1 commission marked unpaid');
    } else {
      toast.success(`${commissionIds.length} commissions marked unpaid`);
    }
    await fetchReport();
    setExecuting(false);
  }, [commissionIds, setExecuting, fetchReport]);

  if (!hasUnpay) {
    return null;
  }

  return (
    <Button
      disabled={disabled || commissionIds.length === 0}
      onClick={handleClick}
    >
      Un&#8209;Pay
    </Button>      
  );
};

export function ExportButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing: boolean) => void,
    disabled?: boolean,
  }
) {
  const hasExport = useHasCapabilities(["EXPORT-REPORT"]);
  const commissionIds = useMemo(
    () => selected.map(
      (row: any) => row.commission_id?.commission_id
    ),
    [selected]
  );
  
  const handleClick = useCallback(async () => {
    setExecuting(true);
    await downloadCsv(
      "report/commission?" + dataToUrlParams({ commission_ids: commissionIds }),
      "commissions.csv"
    );
    if (commissionIds.length === 1) {
      toast.success('1 commission exported');
    } else {
      toast.success(`${commissionIds.length} commissions exported`);
    }
    setExecuting(false);
  }, [commissionIds, setExecuting]);

  if (!hasExport) {
    return null;
  }

  return (
    <Button
      disabled={disabled || commissionIds.length === 0}
      onClick={handleClick}
    >
      Export
    </Button>      
  );
}


export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    PayButton,
    UnPayButton,
    ExportButton,
  ]
};
