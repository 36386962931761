import Status from "../../../../constants/Status";
import {
  FILTER_TYPES,
  ReportFilterConfigBase,
} from "../../filters/config/types";

const statusOptions = [
  { label: "Any", value: "" },
  { label: "No", value: Status.CREDIT_MEMO_OPEN },
  { label: "Yes", value: Status.CREDIT_MEMO_EXPORTED },
];

export const Filters: ReportFilterConfigBase[] = [
  {
    label: "Search",
    fields: "search",
    type: FILTER_TYPES.Query,
    fetchReportOnEnterPress: true,
  },
  {
    label: "Client",
    type: FILTER_TYPES.Client,
    fields: "client_id",
  },
  {
    label: "Date Issued",
    type: FILTER_TYPES.DateRange,
    fields: ["date_issued_date_category", "date_issued_start_stamp", "date_issued_end_stamp"],
    defaultValue: ["this_year", "", ""],
  },
  {
    label: "Exported to Quickbooks?",
    type: FILTER_TYPES.GenericSelect,
    options: statusOptions,
    fields: "status_id",
    defaultValue: "",
    flexBasis: 300,
  },
];
