import React, { useCallback } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { colors, Row, Col, Input } from "@commonsku/styles";

import {
  InvoiceCreditMemo,
  ServiceItem,
  ProductItem,
  Breakdown,
  Cost,
  Tax
} from "../../types/credit_memo";

import useDraft from "./useDraft";
import FormPopup from "../FormPopup";
import { formatMoneyCurrency, formatPercent } from "../../utils";

const CREDIT_MEMO_NOTE_PLACEHOLDER_TEXT = "Add a note (optional)";

const InvoiceDetails = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${colors.primary1['65']};
  border-radius: 8px;
  background: ${colors.white};
`;

const InvoiceDetailsHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  height: 3.5rem;
  padding: 0 0.5rem;
  background: ${colors.neutrals.bg1};
  border-radius: 8px 8px 0 0;
  line-height: 1.5rem;
  font-size: 1rem;
}
`
const CreditMemoItemHeader = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0 4rem;
  background: ${colors.neutrals.bg1};
  font-size: 14px;
  font-weight: 600;
  line-height: 1rem;
  height: 3.5rem;
}
`;

const CreditMemoItemRow = styled.div`
&& {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin: 0 4rem;
  height: 3.5rem;
}
`;

const Client = styled.a`
  font-weight: 700;
`;

const Invoice = styled.span`
  font-weight: 700;
`;

const Project = styled.span`
  font-weight: 400;
`;

const CodeCell = styled.div`
&&& {
  display: flex;
  align-items: center;
  flex: 2 2 16%;
  text-align: left;
  padding-left: 1rem;
  height: 100%;
}
`;

const NameCell = styled.div`
  flex: 3 3 24%;
  text-align: left;
  text-overflow: ellispis;
  overflow: hidden;
  white-space: nowrap;
`;

const InvoicedQtyCell = styled.div`
  flex: 1 1 8%;
  text-align: center;
`;

const QuantityCell = styled.div`
  flex: 1 1 8%;
  text-align: center;
`;

const UnitPriceCell = styled.div`
  flex: 1 1 8%;
  text-align: right;
`;

const SubtotalCell = styled.div`
  flex: 1 1 8%;
  text-align: right;
`;

const TaxCell = styled.div`
  flex: 2 2 16%;
  text-align: left;
`;

const TotalCell = styled.div`
&&& {
  flex: 1 1 8%;
  text-align: right;
  padding-right: 1rem;
}`;

const QuantityInput = styled(Input)`
&&& {
  text-align: center;
  margin: 0;
}
`;

const getSubtotal = (item) => item.unitCost * Number(item.quantity);
const getTotal = (item) => {
  const subtotal = getSubtotal(item);
  return subtotal + item.taxes.reduce((taxes, t) => taxes + Math.round(subtotal * t.percent) / 100, 0);
}

const getTaxes = (item) => Array.from(
  new Set(
    item.taxes.map(tax => `${tax.label} ${formatPercent(tax.percent)}`)
  )
).sort();

const getProductSubtotal = (item) => item.breakdowns.reduce((t, b) => t + getSubtotal(b), 0) + item.costs.reduce((t, c) => t + getSubtotal(c), 0);
const getProductTotal = (item) => item.breakdowns.reduce((t, b) => t + getTotal(b), 0) + item.costs.reduce((t, c) => t + getTotal(c), 0);
const getProductTaxes = (item) => Array.from(
  new Set(
    item.breakdowns.map(b => getTaxes(b)).concat(item.costs.map(c => getTaxes(c))).filter(t => !!t)
  )
).sort();


export default function InvoiceCreditMemoPopup(
  {
    creditMemo,
    onClose,
  }: {
    creditMemo: InvoiceCreditMemo,
    onClose: () => void,
  }
) {
  const { draft, isNew, isValid, isEditable, updater, save } = useDraft(creditMemo);
  if ("INVOICE" !== draft.type) {
    throw new Error("Invalid credit memo");
  }

  const handleError = useCallback(
    (error: unknown) => toast.error("Unable to create credit memo"),
    []
  );

  const title = isNew ? 'Create Credit Memo' : `Credit Memo ${creditMemo.number}`;

  return (
    <FormPopup
      title={title}
      onClose={onClose}
      onSave={save}
      onError={handleError}
      isNew={isNew}
      isValid={isValid}
      isEditable={isEditable && creditMemo.items.length > 0}
    >
      <Row>
        <Col>
          <textarea
            style={{ minHeight: "4.5rem", margin: 0 }}
            value={draft.notes}
            onChange={(e) => updater.setNotes(e.target.value)}
            placeholder={CREDIT_MEMO_NOTE_PLACEHOLDER_TEXT}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <InvoiceDetails>
        <InvoiceDetailsHeader>
          <Client href={`/client/${creditMemo.client.id}`} target="_blank">
            {creditMemo.client.name}
          </Client>
          <Invoice>
            Invoice
            <a
              href={`/project/${creditMemo.project.number}/invoice/${creditMemo.invoice.number}`}
              target="_blank"
            >
              #{creditMemo.invoice.number}
            </a>
          </Invoice>
          <Project>
            Project
            <a
              href={`/project/${creditMemo.project.number}`}
              target="_blank"
            >
              #{creditMemo.project.number}
            </a>
          </Project>
        </InvoiceDetailsHeader>
        <CreditMemoItemHeader>
          <CodeCell>Code</CodeCell>
          <NameCell>Item Name</NameCell>
          <InvoicedQtyCell>Invoiced<br />QTY</InvoicedQtyCell>
          <QuantityCell>Credit<br />QTY</QuantityCell>
          <UnitPriceCell>Unit Price</UnitPriceCell>
          <SubtotalCell>Subtotal</SubtotalCell>
          <TaxCell>Tax</TaxCell>
          <TotalCell>Total</TotalCell>
        </CreditMemoItemHeader>
        {draft.items.length > 0 ? (
          draft.items.map(
            item => (
              <React.Fragment key={item.itemId}>
                <CreditMemoItemRow>
                  <CodeCell>
                    {item.code}
                  </CodeCell>
                  <NameCell>
                    {item.name}
                  </NameCell>
                  <InvoicedQtyCell>
                    {"SERVICE" === item.type ? item.invoicedQuantity : <>&nbsp;</>}
                  </InvoicedQtyCell>
                  <QuantityCell>
                    {"SERVICE" === item.type ?
                      <QuantityInput value={item.quantity} onChange={(e) => updater.setItemQuantity(item.itemId, e.target.value)} /> :
                      <>&nbsp;</>
                    }
                  </QuantityCell>
                  <UnitPriceCell>
                    {"SERVICE" === item.type ? formatMoneyCurrency(item.unitCost, creditMemo.currency) : <>&nbsp;</>}
                  </UnitPriceCell>
                  <SubtotalCell>
                    {formatMoneyCurrency(("SERVICE" === item.type ? getSubtotal(item) : getProductSubtotal(item)), creditMemo.currency)}
                  </SubtotalCell>
                  <TaxCell>
                    {("SERVICE" === item.type ? getTaxes(item) : getProductTaxes(item)).join(", ")}
                  </TaxCell>
                  <TotalCell>
                    {formatMoneyCurrency(("SERVICE" === item.type ? getTotal(item) : getProductTotal(item)), creditMemo.currency)}
                  </TotalCell>
                </CreditMemoItemRow>
                {"PRODUCT" === item.type && item.breakdowns.map(
                  breakdown => (
                    <CreditMemoItemRow key={breakdown.breakdownId} style={{ background: colors.primary1['20'] }}>
                      <CodeCell style={{ background: colors.white }}>
                        &nbsp;
                      </CodeCell>
                      <NameCell>
                        {breakdown.description}
                      </NameCell>
                      <InvoicedQtyCell>
                        {breakdown.invoicedQuantity}
                      </InvoicedQtyCell>
                      <QuantityCell>
                        <QuantityInput value={breakdown.quantity} onChange={(e) => updater.setBreakdownQuantity(item.itemId, breakdown.breakdownId, e.target.value)} />
                       </QuantityCell>
                      <UnitPriceCell>
                        {formatMoneyCurrency(breakdown.unitCost, creditMemo.currency)}
                      </UnitPriceCell>
                      <SubtotalCell>
                        {formatMoneyCurrency(getSubtotal(breakdown), creditMemo.currency)}
                      </SubtotalCell>
                      <TaxCell>
                        {getTaxes(breakdown).join(", ")}
                      </TaxCell>
                      <TotalCell>
                        {formatMoneyCurrency(getTotal(breakdown), creditMemo.currency)}
                      </TotalCell>
                    </CreditMemoItemRow>
                  )
                )}
                {"PRODUCT" === item.type && item.costs.map(
                  cost => (
                    <CreditMemoItemRow key={cost.costId} style={{ background: colors.primary1['20'] }}>
                      <CodeCell style={{ background: colors.white }}>
                        &nbsp;
                      </CodeCell>
                      <NameCell>
                        {cost.description}
                      </NameCell>
                      <InvoicedQtyCell>
                        {cost.invoicedQuantity}
                      </InvoicedQtyCell>
                      <QuantityCell>
                        <QuantityInput value={cost.quantity} onChange={(e) => updater.setCostQuantity(item.itemId, cost.costId, e.target.value)} />
                      </QuantityCell>
                      <UnitPriceCell>
                        {formatMoneyCurrency(cost.unitCost, creditMemo.currency)}
                      </UnitPriceCell>
                      <SubtotalCell>
                        {formatMoneyCurrency(getSubtotal(cost), creditMemo.currency)}
                      </SubtotalCell>
                      <TaxCell>
                        {getTaxes(cost).join(", ")}
                      </TaxCell>
                      <TotalCell>
                        {formatMoneyCurrency(getTotal(cost), creditMemo.currency)}
                      </TotalCell>
                    </CreditMemoItemRow>
                  )
                )}
              </React.Fragment>
            )
          )
        ) : (
          <CreditMemoItemRow style={{ justifyContent: "center" }}>
            No items
          </CreditMemoItemRow>
        )}
      </InvoiceDetails>
    </FormPopup>
  );
}
