import {
    Row, Col, IconButton,
    XIcon, ArrowIcon,
    Popup, PopupHeader
} from '@commonsku/styles';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '../../hooks/useWindowSize';
import NewClientForm from './NewClientForm';
import { getClients } from '../../selectors/clients';
import { createLoadEventTypes, createLoadIndustries } from '../../actions';
import { createLoadClientList } from '../../actions/client';
import AsyncClientSelect from './AsyncClientSelect';
import { BASE_ZINDEX } from '../../popup-factory';

const steps = {
    '0': 'SELECT_CLIENT',
    '1': 'CREATE_CLIENT',
};

export default function SelectClientPopup({
    onClose,
    onSelect,
    ...props
}) {
    const windowSize = useWindowSize();
    const dispatch = useDispatch();
    const clients = useSelector(getClients);
    const clientsOptions = useSelector(state => state.dropdowns.clients);
    const [step, setStep] = useState(0);
    const [clientId, setClientId] = useState(null);

    const showBackButton = step === 1;
    const onClickGoBack = useCallback(() => {
        setStep(s => {
            return s - 1;
        });
    }, []);

    const onClickNewClient = useCallback(() => setStep(1), []);
    const onChangeClient = useCallback(e => {
        setClientId(e?.value ?? "");
    }, []);

    const renderStepForm = useCallback(() => {
        if (steps[step] === 'CREATE_CLIENT') {
            return <NewClientForm
                onCreate={(client_id) => {
                    if (!client_id) { return; }
                    onChangeClient(client_id);
                    setStep(0);
                }}
            />;
        }

        // Popup size is 80% window size with max of 700px
        // 32px popup padding, 2px popup border, 54px popup header, 30px popup content padding
        const maxAvailableHeight = Math.min(windowSize[1] * 0.85, 700) - 32 - 2 - 54 - 30 - 110;
        return (
            <Row>
                <Col xs>
                    <AsyncClientSelect
                        defaultMenuIsOpen={true}
                        onClickNewClient={onClickNewClient}
                        value={clientId}
                        onChange={onChangeClient}
                        maxAvailableHeight={maxAvailableHeight}
                    />
                </Col>
            </Row>
        );
    }, [step, windowSize, onClickNewClient, clientId, onChangeClient]);

    useEffect(() => {
        (async () => {
            await Promise.all([
                dispatch(createLoadEventTypes()),
                dispatch(createLoadIndustries()),
                (_.isEmpty(clients) && _.isEmpty(clientsOptions)) && dispatch(createLoadClientList(clientId ? { clientId } : { 'max-results': 30 })),
            ]);
        })();
    }, [clientId, clients, clientsOptions, dispatch]);

    return (
        <Popup
            onClose={onClose}
            closeOnClickOutside={false}
            header={<PopupHeader className="popup-header" xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                <Col style={{ textAlign: 'left', alignSelf: 'center', display: 'flex' }} xs={8}>
                    {showBackButton ?
                        <IconButton
                            Icon={ArrowIcon}
                            iconProps={{ direction: 'left' }}
                            variant="text"
                            onClick={onClickGoBack}
                            px={5}
                            pt={5}
                            pb={10}
                        />
                        : null}
                    <span className="title" style={{ lineHeight: "54px" }}>Assign To Client</span>
                </Col>
                <Col style={{ textAlign: 'right', alignSelf: 'center' }} xs={4}>
                    <IconButton
                        variant="secondary"
                        size="medium"
                        onClick={onClose}
                        Icon={<XIcon altText="Close" />}
                    />
                </Col>
            </PopupHeader>}
            style={{ maxWidth: 500, height: '85%' }}
            overlayZIndex={BASE_ZINDEX + 100}
            zIndex={BASE_ZINDEX + 200}
        >
            <div style={{ paddingTop: 30, paddingLeft: 5, paddingRight: 5 }}>
                {renderStepForm()}
            </div>
            <div style={{ display: 'flex', minWidth: '400px', justifyContent: 'right', flexDirection: 'row', marginBottom: '1em', marginTop: '470px' }}>
                <IconButton variant='secondary' style={{ marginRight: '1em' }} onClick={onClose}>
                  Cancel
                </IconButton>
                <IconButton variant='primary' disabled={!clientId} onClick={() => {
                    onSelect(clientId);
                }}>Assign</IconButton>
            </div>
        </Popup>
    );
}
