import React from "react";

export const BASE_COLUMN_TYPES = {
  Date: "Date",
  Money: "Money",
  Quantity: "Quantity",
  Percentage: "Percentage",
  Text: "Text",
  Link: "Link",
  Avatar: "Avatar",
  Status: "Status",
  Commission: "Commission",
  Margin: "Margin",
  Notes: "Notes",
  Generic: "Generic",
  SalesTargetActivity: "SalesTargetActivity",
} as const;

export const DEFAULT_SORT_DESC = {
  Date: true,
  Money: true,
  Quantity: true,
  Percentage: true,
  Text: false,
  Link: false,
  Avatar: false,
  Status: false,
  Commission: true,
  Margin: true,
  Notes: false,
  Generic: false,
  SalesTargetActivity: false,
} as const;

export type BaseColumnType =
  (typeof BASE_COLUMN_TYPES)[keyof typeof BASE_COLUMN_TYPES];

interface BaseData {
  tooltipText?: React.ReactNode;
}

interface DateData extends BaseData {
  date: string;
}

interface QuantityData extends BaseData {
  amount: string;
}

interface MoneyData extends BaseData {
  amount: string;
  currency_id: string;
}

interface PercentageData extends BaseData {
  percentage: string | number;
}

interface MarginData extends BaseData {
  amount: string;
  percent: string;
  currency_id: string;
}

interface TextData extends BaseData {
  text: string;
}

interface LinkData extends BaseData {
  text: string;
  href: string;
}

interface AvatarData extends BaseData {
  full_name: string;
  avatar_file_id: string;
}

interface NotesData extends BaseData {
  count: number;
  href: string;
}

interface GenericData extends BaseData {
  cell: React.ReactNode;
}

interface SalesTargetActivityData {
  id: string;
  date: string;
  type: string;
  details: string;
}

interface StatusData extends BaseData {
  order_type: OrderType;
  form_number: string;
  status_name: string;
  href: string;
  filter_orders?: FilterOrder[];
}

interface CommissionData extends BaseData {
  amount: string;
  currency_id: string;
  commission_override: boolean;
  estimated: number;
}

export const ORDER_TYPES = {
  OPPORTUNITY: "OPPORTUNITY",
  "SALES ORDER": "SALES ORDER",
  PRESENTATION: "PRESENTATION",
  INVOICE: "INVOICE",
  ESTIMATE: "ESTIMATE",
} as const;

export type OrderType = (typeof ORDER_TYPES)[keyof typeof ORDER_TYPES];

interface FilterOrder {
  order_type: OrderType;
  form_number: string;
  href: string;
}

interface ColumnTypeToDataMap {
  [BASE_COLUMN_TYPES.Date]: DateData;
  [BASE_COLUMN_TYPES.Money]: MoneyData;
  [BASE_COLUMN_TYPES.Quantity]: QuantityData;
  [BASE_COLUMN_TYPES.Percentage]: PercentageData;
  [BASE_COLUMN_TYPES.Text]: TextData;
  [BASE_COLUMN_TYPES.Link]: LinkData;
  [BASE_COLUMN_TYPES.Avatar]: AvatarData;
  [BASE_COLUMN_TYPES.Status]: StatusData;
  [BASE_COLUMN_TYPES.Margin]: MarginData;
  [BASE_COLUMN_TYPES.Commission]: CommissionData;
  [BASE_COLUMN_TYPES.Notes]: NotesData;
  [BASE_COLUMN_TYPES.Generic]: GenericData;
  [BASE_COLUMN_TYPES.SalesTargetActivity]: SalesTargetActivityData;
}

export type ColumnDataMap<T extends BaseColumnType> = ColumnTypeToDataMap[T];

export interface AvatarOptions {
  withFullName?: boolean;
}

export interface MoneyOptions {
  decimalPlaces?: number;
  colorful?: boolean;
}

export type DateOptions = Intl.DateTimeFormatOptions & {
  locales?: Intl.LocalesArgument;
  emptyValue?: React.ReactNode;
};

export interface PercentageOptions {
  includeTrendline?: boolean;
  decimalPlaces?: number;
}

export interface TextOptions {
  textTransform?: "uppercase" | "lowercase" | "capitalize";
  textColor?: string;
  truncate?: boolean;
}

export const CELL_REGISTRY: Record<BaseColumnType, string> = {
  [BASE_COLUMN_TYPES.Avatar]: "AvatarCell",
  [BASE_COLUMN_TYPES.Date]: "DateCell",
  [BASE_COLUMN_TYPES.Money]: "MoneyCell",
  [BASE_COLUMN_TYPES.Quantity]: "QuantityCell",
  [BASE_COLUMN_TYPES.Percentage]: "PercentageCell",
  [BASE_COLUMN_TYPES.Text]: "TextCell",
  [BASE_COLUMN_TYPES.Link]: "LinkCell",
  [BASE_COLUMN_TYPES.Status]: "StatusCell",
  [BASE_COLUMN_TYPES.Margin]: "MarginCell",
  [BASE_COLUMN_TYPES.Commission]: "CommissionCell",
  [BASE_COLUMN_TYPES.Notes]: "NotesCell",
  [BASE_COLUMN_TYPES.Generic]: "GenericCell",
  [BASE_COLUMN_TYPES.SalesTargetActivity]: "SalesTargetActivityCell",
} as const;

export const REPORT_TOOLTIP_TARGET_ID = "report-table-tooltip";
