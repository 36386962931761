import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { useIdentity } from "../../../../hooks";
import { oauth, fetchCsv, createDownload } from "../../../../utils";
import { useReportContext } from "../../report_context";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more invoices";
  }
  if (selected.length === 1) {
    return "1 invoice selected";
  }
  return `${selected.length} invoices selected`;
}

export function ExportSelectedToCsvButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const order_ids = useMemo(
    () => selected.map((row: any) => row.invoice_number.order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      const { result: max_tax_items, is_zip2tax } = (await oauth(
        "GET",
        "order",
        { check_tax_items: true, reexport: true, order_ids }
      )).json;

      const params = {
        quickbooks: "1",
        reexport: "1",
        max_tax_items, 
        is_zip2tax,
      };

      const rows = await Promise.all(
        order_ids.map(
          order_id => fetchCsv(
            `order/${order_id}?${new URLSearchParams(params).toString()}`,
          ).then(
            blob => blob.text()
          )
        )
      );
      const headers = rows[0].split("\n").shift() + "\n";
      const csv = headers + rows.map(
        row => row.split("\n").filter(Boolean).slice(1).join("\n")
      ).join("\n");
      await createDownload("data:text/plain;charset=utf-8," + encodeURIComponent(csv), "invoice.csv");
    } catch (e) {
      toast.error("Unable to export invoices to CSV");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, order_ids]);

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Export to CSV
    </Button>
  );
}

export function UnExportFromAccountingButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();
  const order_ids = useMemo(
    () => selected.map((row: any) => row.invoice_number.order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    if (order_ids.length === 1) {
      if (!confirm("Are you sure to want to un-export this invoice?")) {
        return;
      }
    } else {
      if (!confirm("Are you sure to want to un-export these invoices?")) {
        return;
      }
    }
    setExecuting(true);
    try {
      await oauth("POST", "order", { action: "unexport", ids: order_ids });
      if (order_ids.length === 1) {
        toast.success("1 invoice unexported");
      } else {
        toast.success(`${order_ids.length} invoices unexported`);
      }
      await fetchReport();
    } catch (e) {
      toast.error("Unable to un-export invoices");
    } finally {
      setExecuting(false);
    }
  }, [setExecuting, order_ids]);

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      Un-Export
    </Button>
  );
}

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    ExportSelectedToCsvButton,
    UnExportFromAccountingButton,
  ]
};
