import React from "react";
import { colors, CheckmarkIcon, XIcon } from "@commonsku/styles";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";
import { ClientColumn, OrderDateColumn, ProjectColumn } from "../common";
import { formatDateFromString } from "../../../../utils";

const CreditMemoNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Text
> = {
  header: "Credit Memo",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["credit_memo_id", "form_number"],
  accessorKey: "form_number",
  canSort: true,
  orderByField: "form_number",
  width: 83,
  dataTransform: ({ form_number }) => ({
    text: form_number,
  }),
};

export const InvoiceNumberColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Link
> = {
  header: "Invoice #",
  type: BASE_COLUMN_TYPES.Link,
  fields: ["job_number", "invoice_form_number"],
  accessorKey: "invoice_number",
  canSort: true,
  orderByField: "invoice_form_number",
  width: 83,
  dataTransform: ({ job_number, invoice_form_number }) => ({
    href: `/project/${job_number}/invoice/${invoice_form_number}`,
    text: invoice_form_number,
  }),
};

export const CreditAmountColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Money
> = {
  header: "Credit Amount",
  type: BASE_COLUMN_TYPES.Money,
  fields: ["total_total", "currency_id"],
  accessorKey: "total",
  canSort: true,
  orderByField: "total_total",
  width: 95,
  moneyOptions: { decimalPlaces: 2 },
  dataTransform: ({ total_total, currency_id }) => ({
    amount: total_total,
    currency_id,
  }),
};

export const DateIssuedColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Date
> = {
  header: "Date Issued",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_issued"],
  accessorKey: "date_issued",
  canSort: true,
  orderByField: "date_issued",
  width: 120,
  dateOptions: { locales: "en-US", day: "numeric", month: "short", year: "numeric" },
};

export const ExportedToQuickbooksColumn: ReportColumnConfigBase<
  typeof BASE_COLUMN_TYPES.Generic
> = {
  header: "Exported to QuickBooks",
  type: BASE_COLUMN_TYPES.Generic,
  fields: ["credit_memo_id", "date_exported"],
  accessorKey: "date_exported",
  canSort: true,
  orderByField: "date_exported",
  width: 83,
  dataTransform: ({ date_exported }) => {
    if (!date_exported) {
      return {
        cell: <XIcon color={colors.neutrals["60"]} />
      }
    }
    return {
      cell: (
        <>
          <CheckmarkIcon color={colors.green.main} />
          {formatDateFromString(
            date_exported,
            {
              locales: "en-US",
              day: "numeric",
              month: "short",
              year: "numeric",
              emptyValue: ""
           }
          )}
        </>
      )
    };
  },
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  ClientColumn,
  ProjectColumn,
  InvoiceNumberColumn,
  CreditMemoNumberColumn,
  CreditAmountColumn,
  DateIssuedColumn,
  ExportedToQuickbooksColumn,
];
