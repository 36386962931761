import React from "react";
import { Col, Loading, Row } from "@commonsku/styles";

import { CreditMemo } from "../../types/credit_memo";

import FormPopup from "../FormPopup";
import ClientCreditMemoPopup from "./ClientCreditMemoPopup";
import InvoiceCreditMemoPopup from "./InvoiceCreditMemoPopup";

const LOADING_CREDIT_MEMO_POPUP_WIDTH = "640px";
const LOADING_CREDIT_MEMO_POPUP_HEIGHT = "324px";

export default function CreditMemoPopup(
  {
    creditMemo,
    onClose,
  }: {
    creditMemo?: CreditMemo,
    onClose: () => void,
  }
) {
  switch (creditMemo?.type) {
    case "CLIENT":
      return (
        <ClientCreditMemoPopup creditMemo={creditMemo} onClose={onClose} />
      );
    case "INVOICE":
      return (
        <InvoiceCreditMemoPopup creditMemo={creditMemo} onClose={onClose} />
      );
    default:
      return (
        <FormPopup
          title="Credit Memo"
          onClose={onClose}
          isNew={false}
          isValid={false}
          isEditable={false}
          width={LOADING_CREDIT_MEMO_POPUP_WIDTH}
          height={LOADING_CREDIT_MEMO_POPUP_HEIGHT}
        >
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </FormPopup>
      );
  }
}
