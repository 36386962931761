import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button, ConfirmPopup } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { useDispatch } from "react-redux";
import { deleteMultipleCreditMemos } from "../../../../redux/credit_memos";
import { useReportContext } from "../../report_context";
import { useHasCapabilities } from "../../../../hooks";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more credit memos";
  }
  if (selected.length === 1) {
    return "1 credit memo selected";
  }
  return `${selected.length} credit memos selected`;
}

export function DeleteCreditMemosButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const canDelete = useHasCapabilities(["DELETE-CREDIT-MEMO"]);
  const [showConfirm, setShowConfirm] = useState(false);
  const dispatch = useDispatch();
  const { fetchReport } = useReportContext();

  const credit_memo_ids = useMemo(
    () => selected.map((row: any) => row.form_number.credit_memo_id),
    [selected]
  );

  const handleDelete = useCallback(async () => {
    setExecuting(true);
    try {
      await dispatch(deleteMultipleCreditMemos(credit_memo_ids));
      fetchReport();
    } catch (e) {
      toast.error("Unable to delete credit memos");
    } finally {
      setExecuting(false);
      setShowConfirm(false);
    }
  }, [setExecuting, credit_memo_ids]);

  if (!canDelete) {
    return null;
  }

  return (
    <Button
      disabled={disabled}
      onClick={() => setShowConfirm(true)}
      variant="error"
    >
      {showConfirm && (
        <ConfirmPopup
          confirmText="Are you sure you want to delete?  Unpaid commissions will be recalculated."
          onClose={() => setShowConfirm(false)}
          onDelete={handleDelete}
        />
      )}
      Delete
    </Button>
  );  
}

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    DeleteCreditMemosButton,
  ]
};
