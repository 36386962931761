import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Col, Loading, Popup, Row, Select } from "@commonsku/styles";

import { PopupHeaderWithCloseIcon } from "../report/action-menu/LoadReportPopup";
import { createDownload, oauth, rawOAuth } from "../../utils";
import { useIdentity } from "../../hooks";

const FormPopupActions = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 0.5rem;
`;

const FormPopupForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  overflow-y: auto;
`;

const FORM_POPUP_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "flex-start",
  gap: "1rem",
};

const FORM_POPUP_CONTENT_STYLE = {
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  flexGrow: 1,
  gap: "1rem",
  overflowY: "hidden",
} as React.CSSProperties;

interface Client {
  client_id: string;
  client_name: string;
}

interface ClientMap {
  [clientId: string]: string;
}

interface SupplierAccount {
  supplier_account_id: string;
  division_name: string;
}

interface SupplierAccountMap {
  [supplierAccountId: string]: string;
}

interface MappingOption {
  Id: string;
  DisplayName: string;
}

export interface AccountingExportReport {
  errors: string[];
  warnings: string[];
  success: string[];
  map_clients: Client[];
  map_suppliers: SupplierAccount[];
  options: {[Id: string]: MappingOption};
}

const DEFAULT_REPORT: AccountingExportReport = {
  errors: [] as string[],
  warnings: [] as string[],
  success: [] as string[],
  map_clients: [] as Client[],
  map_suppliers: [] as SupplierAccount[],
  options: {} as {[Id: string]: MappingOption},
};

type MapType = "CLIENT" | "SUPPLIER-ACCOUNT";

type EntityType = "INVOICE" | "BILL" | "CREDIT-MEMO" | "VENDOR-CREDIT";
const ENTITY_DEFAULTS = {
  "INVOICE": {
    name: "invoice",
    action: "export_invoices",
    idField: "order_ids",
  },
  "BILL": {
    name: "bill",
    action: "export_bills",
    idField: "bill_ids",
  },
  "CREDIT-MEMO": {
    name: "credit memo",
    action: "export_credit_memo",
    idField: "ids",
  },
  "VENDOR-CREDIT": {
    name: "vendor credit",
    action: "export_vendor_credit",
    idField: "ids"
  },
} as const;

export type AccountingSoftware = "QBO" | "XERO" | "WEBHOOK" | "XML" | "CSV";
const ACCOUNTING_DEFAULTS = {
  "QBO": {
    name: "QuickBooks Online",
    controller: "quickbooks",
    headers: {},
    usesReport: true,
    fileExtension: "",
  },
  "XERO": {
    name: "Xero",
    controller: "xero",
    headers: {},
    usesReport: true,
    fileExtension: "",
  },
  "WEBHOOK": {
    name: "Webhook",
    controller: "webhook",
    headers: {},
    usesReport: true,
    fileExtension: "",
  },
  "XML": {
    name: "XML",
    controller: "export",
    headers: { "Content-Type": "application/json", "Accept": "application/xml" },
    usesReport: false,
    fileExtension: "xml",
  },
  "CSV": {
    name: "CSV",
    controller: "export",
    headers: { "Content-Type": "application/json", "Accept": "text/csv" },
    usesReport: false,
    fileExtension: "csv",
  },
} as const;

export function getAccountingSoftwareName(platform: AccountingSoftware) {
  return ACCOUNTING_DEFAULTS[platform].name;
}

export async function exportToAccounting(
  entityType: EntityType,
  accountingSoftware: AccountingSoftware,
  ids: string[],
  clientMap: ClientMap = {},
  supplierAccountMap: SupplierAccountMap = {}
): Promise<AccountingExportReport | null> {
  const mappedClients = Object.keys(clientMap).map(
    client_id => ({ client_id, qbo_customer_ref: clientMap[client_id] })
  );
  const mappedSupplierAccounts = Object.keys(supplierAccountMap).map(
    supplier_account_id => ({ supplier_account_id, qbo_customer_ref: supplierAccountMap[supplier_account_id] })
  );
  const { controller, headers, usesReport, fileExtension } = ACCOUNTING_DEFAULTS[accountingSoftware];
  const { name, action, idField } = ENTITY_DEFAULTS[entityType];
  if (usesReport) {
    const data = {
      action,
      [idField]: ids,
      map: mappedClients.length > 0 ?
        mappedClients :
          (mappedSupplierAccounts.length > 0 ?
            mappedSupplierAccounts :
            undefined)
    };
    const response = await oauth("POST", controller, data);
    const report = { ...DEFAULT_REPORT, ...response.json.report } as AccountingExportReport;
    return report;
  } else {
    const data = {
      actionName: action,
      [idField]: ids,
    };
    const response = await rawOAuth("POST", controller, data, null, headers);
    const blob = await response.blob();
    createDownload(URL.createObjectURL(blob), `${name.replaceAll(' ', '_')}.${fileExtension}`);
    return null;
  }
}

function ExportToAccountingPopup(
  {
    entityType,
    ids,
    onClose,
    report: initialReport = null,
  }: {
    entityType: EntityType,
    ids: string[],
    onClose: () => void,
    report: AccountingExportReport | null,
  }
) {
  const identity = useIdentity();
  const accountingSoftware = (identity.accounting_software ?? "CSV") as AccountingSoftware;
  const accountingName = getAccountingSoftwareName(accountingSoftware);
  const { name: entityName } = ENTITY_DEFAULTS[entityType];
  const [accountingExportReport, setAccountingExportReport] = useState<AccountingExportReport | null>(initialReport);
  const [clientMap, setClientMap] = useState<ClientMap>(
    (accountingExportReport?.map_clients ?? []).reduce((o, c) => ({ ...o, [c.client_id]: "" }), {})
  );
  const [supplierAccountMap, setSupplierAccountMap] = useState<SupplierAccountMap>(
    (accountingExportReport?.map_suppliers ?? []).reduce((o, sa) => ({ ...o, [sa.supplier_account_id]: "" }), {})
  );

  const mapType: MapType = accountingExportReport?.map_suppliers?.length > 0 ? "SUPPLIER-ACCOUNT" : "CLIENT";
  const mapTypeName = "CLIENT" === mapType ? "customer": "supplier";

  useEffect(() => {
    setAccountingExportReport(accountingExportReport ?? initialReport);
  }, [initialReport]);

  const options = useMemo(() => {
    if (!accountingExportReport) {
      return [];
    }
    return [{value: "", label: `Create a new ${name} ${mapTypeName}`}].concat(
      Object.values(accountingExportReport.options ?? {}).map(
        o => ({ value: o.Id, label: o.DisplayName })
      ).toSorted(
        (a, b) => a.label.localeCompare(b.label)
      )
    );
  }, [accountingExportReport]);

  const handleExport = async () => {
    setAccountingExportReport(null);
    const report = await exportToAccounting(
      entityType,
      accountingSoftware,
      ids,
      clientMap,
      supplierAccountMap
    );
    setAccountingExportReport(report);
    setClientMap(report.map_clients.reduce((o, c) => ({ ...o, [c.client_id]: "" }), {}));
    setSupplierAccountMap(report.map_suppliers.reduce((o, sa) => ({ ...o, [sa.supplier_account_id]: "" }), {}));
  };

  let content = null;
  if (!accountingExportReport) {
    content = <Loading />;
  } else if (accountingExportReport.map_clients.length > 0) {
    content = (
      <>
        <p>The {entityName}s you are trying to export belong to commonsku clients with no associated {accountingName} customer.  You must associate these commonsku clients to clients in {accountingName} in order to proceed with the export.</p>
        {accountingExportReport.map_clients.map(
          (client) => (
            <Row key={client.client_id}>
              <Col>{client.client_name}</Col>
              <Col>
                <Select
                  inPopup
                  options={options}
                  value={options.find(o => o.value === (clientMap[client.client_id] ?? ""))}
                  onChange={o => setClientMap({ ...clientMap, [client.client_id]: o.value })}
                />
              </Col>
            </Row>
          )
        )}
      </>
    );
  } else if (accountingExportReport.map_suppliers.length > 0) {
    content = (
      <>
        <p>The {entityName}s you are trying to export belong to commonsku divisions with no associated {accountingName} supplier.  You must associate these commonsku divisions to suppliers in {accountingName} in order to proceed with the export.</p>
        {accountingExportReport.map_suppliers.map(
          (supplierAccount) => (
            <Row key={supplierAccount.supplier_account_id}>
              <Col>{supplierAccount.division_name}</Col>
              <Col>
                <Select
                  inPopup
                  options={options}
                  value={options.find(o => o.value === (supplierAccountMap[supplierAccount.supplier_account_id] ?? ""))}
                  onChange={o => setSupplierAccountMap({ ...supplierAccountMap, [supplierAccount.supplier_account_id]: o.value })}
                />
              </Col>
            </Row>
          )
        )}
      </>
    );
  } else {
    content = (
      <>
        {accountingExportReport.success.length > 0 && (
          <>
            <p>Export completed successfully.</p>
            <p>Operations completed:</p>
            <ul>
              {accountingExportReport.success.map((message, index) => <li key={index}>{message}</li>)}
            </ul>
          </>
        )} 
        {accountingExportReport.errors.length > 0 && (
          <>
            <p>The following error(s) were found:</p>
            <ul>
              {accountingExportReport.errors.map((message, index) => <li key={index}>{message}</li>)}
            </ul>
          </>
        )} 
        {accountingExportReport.warnings.length > 0 && (
          <>
            <p>The following warning(s) were found:</p>
            <ul>
              {accountingExportReport.warnings.map((message, index) => <li key={index}>{message}</li>)}
            </ul>
          </>
        )} 
      </>
    );
  }

  return (
    <Popup
      closeOnEsc
      closeOnClickOutside={false}
      onClose={onClose}
      style={FORM_POPUP_STYLE}
      popupContentStyle={FORM_POPUP_CONTENT_STYLE}
      header={
        <PopupHeaderWithCloseIcon
          title={`${getAccountingSoftwareName(accountingSoftware)} Export Report`}
          onPopupClose={onClose}
        />
      }
    >
      <FormPopupForm>
        {content}
      </FormPopupForm>
      <FormPopupActions>
        {accountingExportReport?.map_clients?.length > 0 && <Button onClick={handleExport}>Map Clients and Export</Button>}
        {accountingExportReport?.map_suppliers?.length > 0 && <Button onClick={handleExport}>Map Divisions and Export</Button>}
        <Button secondary disabled={!accountingExportReport} onClick={onClose}>Close</Button>
      </FormPopupActions>
    </Popup>
  );
}

export default ExportToAccountingPopup;
