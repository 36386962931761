import React, { useCallback } from "react";
import { toast } from "react-toastify";
import { LabeledIconInput, Row, Col } from "@commonsku/styles";

import { ClientCreditMemo } from "../../types/credit_memo";
import { getCurrencySymbol } from "../../utils";

import useDraft from "./useDraft";
import FormPopup from "../FormPopup";
import AsyncClientSelect from "../project/AsyncClientSelect";

const CLIENT_CREDIT_MEMO_POPUP_WIDTH = "640px";
const CLIENT_CREDIT_MEMO_POPUP_HEIGHT = "324px";
const CREDIT_MEMO_NOTE_PLACEHOLDER_TEXT = "Add a note (optional)";

export default function ClientCreditMemoPopup(
  {
    creditMemo,
    onClose,
  }: {
    creditMemo: ClientCreditMemo,
    onClose: () => void,
  }
) {
  const { draft, isNew, isValid, isEditable, updater, save } = useDraft(creditMemo);
  if ("CLIENT" !== draft.type) {
    throw new Error("Invalid credit memo");
  }

  const handleError = useCallback(
    (error: unknown) => toast.error("Unable to create credit memo"),
    []
  );

  const title = isNew ? 'Create Credit Memo' : `Credit Memo ${creditMemo.number}`;

  return (
    <FormPopup
      title={title}
      onClose={onClose}
      onSave={save}
      onError={handleError}
      isNew={isNew}
      isValid={isValid}
      isEditable={isEditable}
      width={CLIENT_CREDIT_MEMO_POPUP_WIDTH}
      height={CLIENT_CREDIT_MEMO_POPUP_HEIGHT}
    >
      <Row>
        <Col>
          <textarea
            style={{ minHeight: "4.5rem", margin: 0 }}
            value={draft.notes}
            onChange={(e) => updater.setNotes(e.target.value)}
            placeholder={CREDIT_MEMO_NOTE_PLACEHOLDER_TEXT}
            disabled={!isEditable}
          />
        </Col>
      </Row>
      <Row style={{ gap: "1rem" }}>
        <Col>
          <AsyncClientSelect
            label="Client"
            labelStyle={{ lineHeight: "24px" }}
            name="client_id"
            value={draft.client.id}
            onChange={(client) => updater.setClient(client)}
            isDisabled={!isEditable}
          />
        </Col>
        <Col>
          <LabeledIconInput
            label="Total amount to refund"
            labelOnTop
            Icon={<span>{getCurrencySymbol("en-US", draft.currency)}</span>}
            value={draft.total}
            onChange={(e) => updater.setTotal(e.target.value)}
            onFocus={(e) => e.target.select()}
          />
        </Col>
      </Row>
    </FormPopup>
  );
}
