import React, { useCallback, useMemo, useState} from "react";
import { Button } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { oauth } from "../../../../utils";
import SelectClientPopup from "../../../project/SelectClientPopup";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more contacts";
  } else if (selected.length === 1) {
    return "1 contact selected";
  } else {
    return `${selected.length} contacts selected`;
  }
};

export function AssignHubspotContactToClientButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean
  }
) {
  const [showPopup, setShowPopup] = useState(false);
  const handleClick = useCallback(
    () => {
      setExecuting(true);
      setShowPopup(true);
    },
    [setExecuting]
  );

  const handleClose = useCallback(
    () => {
      setExecuting(false);
      setShowPopup(false);
    },
    [setExecuting]
  );

  const contact_import_hubspot_contact_ids = useMemo(
    () => selected.map((s: any) => s.firstname.contact_import_hubspot_contact_id),
    [selected]
  );

  const handleSelect = useCallback(
    async (client_id: string) => {
      const res = await oauth(
        "PUT",
        "contact-import-hubspot-contacts",
        {
          client_id,
          contact_import_hubspot_contact_ids,
        }
      );
      return res;
    },
    [setExecuting, contact_import_hubspot_contact_ids]
  );

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      {false !== showPopup && (
        <SelectClientPopup
          onClose={handleClose}
          onSelect={handleSelect}
        />
      )}
      Assign To Client
    </Button>
  );
}

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    AssignHubspotContactToClientButton,
  ]
};
