import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "@commonsku/styles";
import { BulkActionConfigBase } from "../index";
import { useIdentity } from "../../../../hooks";
import { oauth } from "../../../../utils";
import ExportToAccountingPopup, {
  AccountingSoftware,
  exportToAccounting,
  getAccountingSoftwareName,
  AccountingExportReport
} from "../../../finance/ExportToAccountingPopup";
import { useReportContext } from "../../report_context";

export function prepareLabel<
  T extends Record<string, unknown> = Record<string, unknown>
>(selected: T[]): React.ReactNode {
  if (selected.length === 0) {
    return "Select one or more invoices";
  }
  if (selected.length === 1) {
    return "1 invoice selected";
  }
  return `${selected.length} invoices selected`;
}

export function ExportToAccountingButton<T extends Record<string, unknown> = Record<string, unknown>>(
  {
    selected,
    setExecuting,
    disabled,
  }: {
    selected: T[],
    setExecuting: (executing?: boolean) => void,
    disabled?: boolean,
  }
) {
  const { fetchReport } = useReportContext();
  const [accountingExportReport, setAccountingExportReport] = useState<AccountingExportReport | null | false>(false);
  const identity = useIdentity();
  const accountingSoftware = (identity.accounting_software ?? "CSV") as AccountingSoftware;
  const label = `Export to ${getAccountingSoftwareName(accountingSoftware)}`;

  const order_ids = useMemo(
    () => selected.map((row: any) => row.invoice_number.order_id),
    [selected]
  );

  const handleClick = useCallback(async () => {
    setExecuting(true);
    try {
      setAccountingExportReport(null);
      const report = await exportToAccounting("INVOICE", accountingSoftware, order_ids);
      if (report) {
        setAccountingExportReport(report);
      } else {
        setExecuting(false);
        setAccountingExportReport(false);
        fetchReport();
      }
    } catch (e) {
      toast.error("Unable to export invoices");
      setAccountingExportReport(false);
      setExecuting(false);
    }
  }, [accountingSoftware, fetchReport, setExecuting, order_ids]);

  const handleClose = useCallback(() => {
    setAccountingExportReport(false);
    setExecuting(false);
    fetchReport();
  }, [fetchReport, setExecuting]);

  return (
    <Button
      disabled={disabled}
      onClick={handleClick}
    >
      {false !== accountingExportReport && <ExportToAccountingPopup
        entityType="INVOICE"
        ids={order_ids}
        onClose={handleClose}
        report={accountingExportReport}
      />}
      {label}
    </Button>
  );
}

export const BulkActions: BulkActionConfigBase = {
  prepareLabel,
  actions: [
    ExportToAccountingButton,
  ]
};
