import React from "react";
import { BASE_COLUMN_TYPES, BaseColumnType } from "../../columns/config/types";
import { ReportColumnConfigBase } from "../../columns/config/ReportColumnConfig";

const FirstnameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "First Name",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["firstname", "contact_import_hubspot_contact_id"],
  accessorKey: "firstname",
  canSort: true,
  orderByField: "firstname",
  width: 136,
  dataTransform: ({ firstname }) => ({
    text: firstname
  }),
};

const LastnameColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Last Name",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["lastname"],
  accessorKey: "lastname",
  canSort: true,
  orderByField: "lastname",
  width: 136,
};

const EmailColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Email",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["email"],
  accessorKey: "email",
  canSort: true,
  orderByField: "email",
  width: 136,
};

const CompanyColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Company",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["company"],
  accessorKey: "company",
  canSort: true,
  orderByField: "company",
  width: 136,
};

const DateColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Date> = {
  header: "Import Date",
  type: BASE_COLUMN_TYPES.Date,
  fields: ["date_created"],
  accessorKey: "date_created",
  canSort: true,
  orderByField: "date_created",
  width: 100,
};

const NotesColumn: ReportColumnConfigBase<typeof BASE_COLUMN_TYPES.Text> = {
  header: "Notes",
  type: BASE_COLUMN_TYPES.Text,
  fields: ["matching_error"],
  accessorKey: "matching_error",
  canSort: true,
  orderByField: "matching_error",
  width: 300,
  textOptions: { truncate: true },
  dataTransform: ({ matching_error }) => ({
    text: matching_error,
    tooltipText: matching_error,
  }),
};

export const Columns: ReportColumnConfigBase<BaseColumnType>[] = [
  FirstnameColumn,
  LastnameColumn,
  EmailColumn,
  CompanyColumn,
  DateColumn,
  NotesColumn,
];
