import { Filters } from "./filters";
import { Columns } from "./columns";
import { ReportConfigBase } from "../index";
import { EReportTypes } from "../../routes/report_types";
import { BulkActions } from "../contact-import-hubspot-contact/bulk-actions";

const ContactImportHubspotContactReportConfig: ReportConfigBase = {
  type: EReportTypes.ContactImportHubspotContact,
  endpoint: "contact-import-hubspot-contacts",
  filtersBase: Filters,
  summaryBase: [],
  columnsBase: Columns,
  withSelection: true,
  bulkActions: BulkActions,
  resultsPerPage: 50,
  refetchOnFilterChange: true,
  scrollOnFilterChange: false,
  intro: {
    description:
      "All unmapped hubspot contact",
    url: "https://help.commonsku.com/knowledge/hubspot",
  },
};

export default ContactImportHubspotContactReportConfig;
